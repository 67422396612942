<template>
    <div>

        <h2 class="section-header">
            <img src="@/assets/images/feedback.svg" alt="feedback"/>    
            Feedback
        </h2>

        <component v-bind:is="currentComponent" 
        :currentTab="currentTab" 
        :messageObj="message"
        :inboxMessages ="inboxMessages"
        :fetchInbox="fetchInbox"
        @changeTab="changeTab($event)"
        @changeComponent="changeComponent($event)"
        @getInboxMessages="getInboxMessages()"></component>

    </div>
</template>


<script>
import FeedbackMain from './FeedbackMain';
import FeedbackInbox from './FeedbackInbox';

import messages from '../services/messages';

export default {
    name: "Feedback",
    components: {
        FeedbackMain,
        FeedbackInbox,
    },
    data() {
        return {
            userID: 0,
            currentTab: "compose",
            currentComponent: "FeedbackMain",
            message: {},
            inboxMessages: [],
            fetchInbox: "",
        }
    },
    methods: {
        // I decided to keep this changeTab function in this parent component instead of the child
        // component which is FeedbackMain, because I am storing the currentTab property on the 
        // localStorage and I dont want the value to change unless this parent component is destroyed
        // this is why is created a beforeDestroy() method.
        changeTab(event) {
            const tabItems = document.querySelectorAll(".tabs .tab-item");

            Array.from(tabItems).forEach(item => item.classList.remove('active'));

            this.currentTab = event.target.innerText.toLowerCase();
            this.$store.dispatch('setFeedbackCurrentTab', this.currentTab);
        },
        changeComponent(event) {
            if (!event.element) {
                this.currentComponent = 'FeedbackMain';
            } else if (event.element && !event.element.target.classList.contains('checkbox') &&
            !event.element.target.classList.contains('svg-star')) {
                messages.getMessageById(event.msgId)
                .then(data => {
                    if(data.success) {
                        this.message = data.message;
                        this.currentComponent = 'FeedbackInbox';
                    } else {
                        this.$toast.error(data.error);
                    }
                })
                .catch(() => {
                    this.$toast.error("please check your network and refresh the page");
                })
            }
        },
        getInboxMessages() {
            this.fetchInbox = 'loading';
            messages.inboxMessages(this.userID)
            .then((data) => {
                if (data.success) {
                    this.inboxMessages = data.messages;
                } else {
                    this.$toast.error(data.error);
                }
            })
            .catch(() => {
                this.$toast.error("please check your network and refresh the page");
            })
            .finally(() => {
                this.fetchInbox = 'loaded';
            })
        },
    },
    created() {
        let user = this.$store.getters.getUser;
        this.userID = user.id;

        this.currentTab = this.$store.getters.getFeedbackCurrentTab; 
        
        this.getInboxMessages();
    },
    beforeDestroy() {
        this.$store.dispatch('setFeedbackCurrentTab', 'compose');
    }
    
}
</script>

<style scoped>
@import "../styles/section-header.css"; 
</style>