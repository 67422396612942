<template>
    <div class="feedback-inbox">

        <div class="section-handler">
            <button @click="status='reply'">
                <img src="@/assets/images/reply.svg" alt=""> Reply
            </button>
        </div>

        <div class="message-section">
            <div class="back" @click="$emit('changeComponent', {})">
                <button class="image-container">
                    <img src="@/assets/images/back-arrow.svg" alt="">
                </button>
                Back to inbox
            </div>


            <div class="message-content" v-if="status==='read'">
                <div class="message-header">
                    <div class="message-labels">
                        <div class="message-label">
                            <p>Subject:</p>
                            <p>{{messageObj.subject}}</p>
                        </div>

                        <div class="message-label">
                            <p>From:</p>
                            <p>{{messageObj.senderemail}}</p>
                        </div>
                    </div>
                    <p class="message-time">
                        <span>{{getTime(messageObj.created_at)}}
                        </span>{{"(" + getTimeAgo(messageObj.created_at) + " ago )"}}
                    </p>
                </div>

                <div class="message-body">
                    <p>{{messageObj.message}}</p>
                </div>
            </div>


            <div class="reply-section" v-if="status==='reply'">
                <div class="reply-header">
                    <div class="reply-address">
                        <img src="@/assets/images/reply.svg" alt="">
                        <!-- <p><span>Shelta</span> &lt;support@myshelta.com&gt;</p> -->
                        <p>{{userEmail}}</p>
                    </div>
                    <button class="close" @click="status='read'">&times;</button>
                </div>
                <div class="reply-body">
                    <textarea></textarea>
                </div>
                <div class="reply-action">
                    <button class="send">Send</button>
                    <button class="attach-file">
                        <img src="@/assets/images/attachment.svg" alt="">
                    </button>
                    <button class="delete" @click="status = 'read'">
                        <img src="@/assets/images/delete.svg" alt="">
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "FeedbackInbox",
    data() {
        return {
            status: 'read',
            userEmail: '',
        }
    },
    props: ["messageObj"],
    methods: {
        getTime (date) {
            let index = date.indexOf("T");
            let time = date.slice((index + 1), (index + 9));
            let sector = time.split(":");
            if (+(sector[0]) > 11) {
                return (sector[0] - 12) + ":" + sector[1] + " PM";
            } else {
                return sector[0] + ":" + sector[1] + " AM";
            }
        },
        getTimeAgo (date) {
            const createdAtDate = new Date(date);
            const currentDate = new Date();
            let timeAgo = (currentDate.getTime()) - (createdAtDate.getTime());

            timeAgo = timeAgo / 1000;
            let roundedTimeAgo = Math.round(timeAgo);
            let minTimeAgo = Math.round(timeAgo / 60);
            let hourTimeAgo = Math.round(timeAgo / (60 * 60));
            let dayTimeAgo = Math.round(timeAgo / (60 * 60 * 24));
            let monthTimeAgo = Math.round(timeAgo / (60 * 60 * 24 * 31));
            let yearTimeAgo = Math.round(timeAgo / (60 * 60 * 24 * 365));

            if (yearTimeAgo >= 1) {
                return yearTimeAgo + (yearTimeAgo > 1 ? ' years' : ' year');
            } else if (monthTimeAgo >= 1) {
                return monthTimeAgo + (monthTimeAgo > 1 ? ' months' : ' month');
            } else if (dayTimeAgo >= 1) {
                return dayTimeAgo + (dayTimeAgo > 1 ? ' days' : ' day');
            } else if (hourTimeAgo >= 1) {
                return hourTimeAgo + (hourTimeAgo > 1 ? ' hours' : ' hour');
            } else if (minTimeAgo >= 1) {
                return minTimeAgo + (minTimeAgo > 1 ? ' minutes' : ' minute');
            } else {
                return roundedTimeAgo + (roundedTimeAgo > 1 ? ' seconds' : ' second');
            }
        }
    },
    created() {
        let user = this.$store.getters.getUser;

        this.userEmail = user.email;
    }
}
</script>

<style scoped>
.feedback-inbox {
    margin-top: 32px;
}
.section-handler {
    display: flex;
    justify-content: flex-end;
}
.section-handler button {
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    background: #fff;
    width: 89px;
    height: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section-handler button img {
    margin-right: 10px;
}

.message-section {
    background: #fff;
    margin-top: 25px;
    font-weight: 600;
    font-size: 12px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-bottom: 1px solid transparent;
}
.message-section .back {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    height: 60px;
    padding: 0 40px;
    display: flex;
    align-items: center;
}
.back .image-container {
    background: inherit;
    margin-right: 13px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.back .image-container:hover, .back .image-container:focus {
    background: #c4c4c4;
}


/* message content section */
.message-content {
    background: #fff;
    padding: 40px;
}
.message-header span {
    font-family: "Gotham-Book";
}
.message-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}
.message-labels {
    max-width: fit-content;
    margin-right: 20px;
}
.message-time {
    margin-left: auto;
    line-height: 2.5;
}

.message-label {
    display: grid;
    grid-template-columns: 100px 1fr;
}
.message-label p {
    line-height: 2.5;
}
.message-label p:first-of-type {
    font-family: "Gotham-Book";
    margin-right: 15px;
}
.message-body {
    background: #f5f5f5;
    padding: 25px 35px;
}
.message-body p {
    font-size: 13px;
    line-height: 2.62;
}


/* reply message section */
.reply-section {
    margin: 30px 35px;
    box-shadow: 4px 4px 20px 6px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}
.reply-header {
    height: 72px;
    padding: 0 20px 12px 33px;
    box-shadow: 4px 4px 20px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 5px 5px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}
.reply-header .reply-address {
    display: flex;
}
.reply-address img {
    margin-right: 20px;
    width: 13px;
    height: 13px;
}
.reply-header .close {
    background: inherit;
    font-family: "Gotham-Medium";
    font-size: 30px;
}
.reply-body textarea {
    border: 0;
    width: 100%;
    height: 350px;
    padding: 20px;
    font-family: "Gotham-Book";
    font-size: 14px;
}
.reply-body textarea:focus {
    outline: 0;
}
.reply-action {
    display: flex;
    align-items: center;
    height: 72px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px 6px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 5px 5px;
    padding: 0 35px;
}
.reply-action button {
    background: inherit;
    margin-right: 20px;
}
.reply-action .send {
    width: 82px;
    height: 35px;
    background: var(--green-color);
    color: #fff;
}
.reply-action .send:hover, .reply-action .send:focus {
    background: var(--deep-green);
}
.reply-action .delete, .reply-action .attach-file {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reply-action .delete:focus, .reply-action .delete:hover, .reply-action .attach-file:focus, .reply-action .attach-file:hover {
    border-radius: 50%;
    background: #c4c4c4;
    outline: 0;
}
@media (max-width: 991.98px) {
    .reply-header, .reply-action {
        height: 60px;
        padding-bottom: 0;
    }
}
@media (max-width: 575.98px) {
    .message-section .back {
        padding: 0 20px;
        height: 50px;
    }
    .message-content {
        padding: 20px;
    }
    .message-label {
        grid-template-columns: 60px 1fr;
    }
    .message-body {
        padding: 20px;
    }

    .reply-header, .reply-action {
        padding: 0 20px;
    }
    .reply-body textarea {
        height: 250px;
    }
}
@media (max-width: 424.98px) {
    .message-label {
        grid-template-columns: 1fr;
        grid-template-rows: 20px 1fr;
    }
    .message-time {
        line-height: 1.5;
    }
    .message-label p {
        line-height: 1.5;
        margin-bottom: 10px;
    }

    .reply-section {
        margin: 20px;
    }
    .reply-address {
        flex-direction: column;
    }
    .reply-address img {
        margin-bottom: 10px;
    }
    .reply-header .close {
        margin-top: -20px;
        line-height: 0.8;
    }
}
</style>