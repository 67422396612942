import client from './requestclient';

const messages = {
    getCategories: async (id) => {
        return await client({URL_PATH: `fetchrecepientsandmailcategories/${id}`, method: "GET"});
    },

    // TODO: we do not yet have a design to show list of sent messages
    sentMessages: async (id) => {
        return await client({URL_PATH: `fetchsentmessages/${id}`, method: "GET"})
    },

    inboxMessages: async (id) => {
        return await client({URL_PATH: `fetchinbox/${id}`, method: "GET"})
    },

    sendMessage: async (body) => {
        return await client({URL_PATH: 'sendinternalmessage', method: "POST", body: body})
    },

    deleteMessages: async (body) => {
        return await client({URL_PATH: 'deletemessages', method: "POST", body})
    },

    getMessageById: async (id) => {
        return await client({URL_PATH: `getmessagebyid/${id}`, method: "GET"})
    }
}

export default messages;